// if (window.innerWidth <= 480) {
//   var lazyImages = document.querySelectorAll('img');
//   lazyImages.forEach(function(img) {
//       var src = img.getAttribute('src');
//       // Replace multiple file extensions with .webp
//       var mobileSrc = src.replace(/\.(png|jpg|jpeg)/, '.webp').replace('assets', 'mobile-assets');
//       img.setAttribute('src', mobileSrc);
//   });
// }
// else {
//   var lazyImages = document.querySelectorAll('img');
//   lazyImages.forEach(function(img) {
//       var src = img.getAttribute('src');
//       // Replace multiple file extensions with .webp
//       var desktopSrc = src.replace(/\.(png|jpg|jpeg)/, '.webp');
//       img.setAttribute('src', desktopSrc);
//   });
// }
import "index.scss"
import "custom.scss"
import "lazysizes.min.js"
import "custom.js"
// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

console.info("Bridgetown is loaded!")
